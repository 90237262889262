<template>
  <v-card>
    <v-card-title>
      <menu-title
        title="News"
        class="mt-6"
      >
        <v-btn
          outlined
          color="#b31616"
          @click="newArticleDialog=true"
        >
          <v-icon class="mr-2">mdi-plus</v-icon>
          New Article
        </v-btn>
      </menu-title>
    </v-card-title>
    <v-card-text>

    </v-card-text>

    <news-article-dialog
      v-model="newArticleDialog"
      v-if="newArticleDialog"
    ></news-article-dialog>
  </v-card>
</template>
<script>
import menuTitle from "../../components/helpers/menu_title.vue";
import newsArticleDialog from "../../components/news_article_dialog.vue";
export default {
  name: "News",
  components: {
    menuTitle,
    newsArticleDialog,
  },
  props: {},
  data: () => ({
    newArticleDialog: false,
  }),
  methods: {},
  mounted() {},
};
</script>