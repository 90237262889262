<template>
  <v-card class="ma-2">
    <v-img
      v-if="image"
      height="60"
      :src="image"
    >
      <v-app-bar
        flat
        color="rgba(0, 0, 0, 0)"
      >
        <v-toolbar-title class="title white--text pl-0">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <slot name="buttons"></slot>

      </v-app-bar>
    </v-img>
    <v-app-bar
      v-else
      flat
      :color="color"
    >
      <v-toolbar-title class="title white--text pl-0">
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <slot name="buttons"></slot>

    </v-app-bar>
    <v-card-text>
      <div
        class="mt-5"
        v-html="compiledMarkdown"
      ></div>
    </v-card-text>
  </v-card>
</template>

<script>
import sanitizeHtml from "sanitize-html";
import { marked } from "marked";
export default {
  name: "articleItem",
  props: {
    image: {
      type: String,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    compiledMarkdown: function () {
      return sanitizeHtml(marked(this.text));
    },
  },
};
</script>

<style>
</style>