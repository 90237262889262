<template>
  <v-dialog
    v-model="dialog"
    max-width="1500"
    persistent
  >
    <v-card v-if="dialog">
      <v-card-title>
        <menu-title title="New Article"></menu-title>
      </v-card-title>
      <v-card-text>
        <v-row>

          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              label="Title"
              v-model="title"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              outlined
              hide-details
              lable="Content"
              v-model="text"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-radio-group
            v-model="headerMode"
            row
          >
            <v-radio
              label="Use color"
              value="color"
            ></v-radio>
            <v-radio
              label="Use image"
              value="image"
            ></v-radio>
          </v-radio-group>
          <v-spacer></v-spacer>
        </v-row>
        <v-row
          no-gutters
          v-if="headerMode=='color'"
        >
          <v-col
            class="d-flex justify-center"
            cols="12"
          >
            <v-color-picker
              canvas-height="110"
              dot-size="20"
              mode="hexa"
              swatches-max-height="200"
              v-model="color"
            ></v-color-picker>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          v-if="headerMode=='image'"
        >
          <v-text-field
            outlined
            hide-details
            label="Image URL"
            v-model="src"
          ></v-text-field>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          @click="dialog=false"
        >Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="previewDialog=true"
        >Preview</v-btn>
        <v-btn
          outlined
          @click="addNews"
        >Add</v-btn>
      </v-card-actions>

      <v-dialog
        v-model="previewDialog"
        max-width="1200"
      >
        <article-item
          :color="headerMode == 'color' ? color: null"
          :image="headerMode == 'image' ? src: null"
          :title="title"
          :text="text"
        ></article-item>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import articleItem from "./news_article_item.vue";
import menuTitle from "./helpers/menu_title.vue";
export default {
  name: "newsArticleDialog",
  props: {
    value: Boolean,
  },
  components: {
    menuTitle,
    articleItem,
  },
  computed: {
    headerValue: {
      get() {
        if (this.headerMode == "color") return this.color;
        else return this.src;
      },
      set(value) {},
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data: () => ({
    previewDialog: false,
    headerMode: "color",
    color: "#B31616",
    src: "",
    title: "",
    text: `## Your Text
# Or whatever
__goes here__`,
  }),
  methods: {
    addNews: function () {
      console.log("adding...");
    },
  },
  async mounted() {},
};
</script>

<style>
</style>